var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dropdown",
        { attrs: { trigger: "click" }, on: { command: _vm.handleCommand } },
        [
          _c("i", { staticClass: "el-icon-more" }),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.actionList, function (item) {
              return _c(
                "el-dropdown-item",
                {
                  key: item.key,
                  attrs: { icon: item.icon, command: item.key },
                },
                [_vm._v(_vm._s(item.label))]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }